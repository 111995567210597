<template>

  <the-modal v-if="showIntro" class="gps-modal" @closeModal="closeModal" :isBig="true" :showHeader="true" :showHeaderHeading="false">

    <template v-slot:body>

      <div class="full-width">
        <center class="mt10"><h5 class="mb20">Wo bin ich?</h5>
          <span>Diese Funktion ermöglicht es dir, deine Position in Echtzeit mit der empfohlenen Route abzugleichen.</span>
          <span>So bleibst du der Kultur immer auf der Spur!</span>
          <img class="mb20 mt20" style="width: 100%;" src="/assets/intro/rectangle.png" alt="Intro-Img 1"/>
          <span>Dafür muss der Kiel.KulturKompass auf dein GPS bzw. deinen Standort zugreifen. Deine Position wird dabei ausschließlich auf deinem Endgerät verwendet. Mehr Informationen dazu erhälst du in der <a href="/datenschutz">Datenschutzerklärung</a>.</span>
        </center>
      </div>

    </template>
    <template v-slot:footer>
      <div class="btn btn-fill mt20" @click="activateUserLocation">
        Aktivieren      
      </div>
    </template>
  </the-modal>

</template>
<script>
  import { latLng, icon } from 'leaflet';

  export default {
    name: 'GpsModal',
    components: {
      TheModal: () =>import('@/components/modal/show.vue'),
    },
    data() {
      return {
        loader: null,
        showIntro: true,
      }
    },
    methods: {
      closeModal(){
        this.showIntro = false;
        this.$emit("closeModal");
      },
      activateUserLocation(){
        this.$emit("active");
        this.closeModal();
      }
    }
  };
</script>

<style lang="scss">
  @import '@/scss/_variables.scss';

  .gps-modal{

    .modal-header{
      display: block;
      padding: 0px;
    }

    .modal-container {
     margin-top: 60px;
     max-height: 700px;

     @media(max-height: 800px){
       max-height: 650px;
     }

     @media(max-height: 700px){
      max-height: 580px;
    }

    @media(max-height: 600px){
     max-height: 500px;
   }
 }

 .close{
  position: relative;
  left: 0;
  top: 0;
  z-index: 1001;
}
}

</style>
